import "./App.css";
import "./custom.css";
// import Patch from "./Components/Patch/Patch";
import Patch from "./Componenets/Patch/Patch";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPatchVisible, setIsPatchVisible] = useState(false); // State for patch visibility in mobile view
  const [colorCode, setColorCode] = useState("");
  const [btnColor, setBtnColor] = useState("");
  const [brandId, setBrandId] = useState("");
  const spanRef = useRef(null);

  useEffect(() => {
    const spanElement = document.getElementById("root");
    if (spanElement) {
      const typeId = spanElement.dataset.typeid;
      const brand_id = spanElement.dataset.brandid;
      const colorcode = spanElement.dataset.colorcode;
      const btncolor = spanElement.dataset.btncolor;

      setColorCode(colorcode);
      setBtnColor(btncolor);
      setBrandId(+brand_id);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const togglePatchVisibility = () => {
    setIsPatchVisible((prev) => !prev);
  };

  return (
    <div className="App">
      {windowWidth <= 650 ? (
        <>
          {/* Show "Book Now" button when patch is not visible */}
          {!isPatchVisible && (
            <div
              className="book-now-button-mobile"
              onClick={togglePatchVisibility}
            >
              Book Now
            </div>
          )}

          {/* Show patch with animation when isPatchVisible is true */}
          {isPatchVisible && (
            <div className="mobile-patch ">
              <Patch
                colorCode={colorCode}
                btnColor={btnColor}
                brandId={brandId}
              />
              <div className="close-icon" onClick={togglePatchVisibility}>
                <CloseIcon />
              </div>
            </div>
          )}
        </>
      ) : (
        // Always show the patch in desktop mode
        <Patch colorCode={colorCode} btnColor={btnColor} brandId={brandId} />
      )}
    </div>
  );
}

export default App;
