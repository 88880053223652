import React, { useState, useEffect } from "react";
import Calender from "../Calendar/Calender";
import Button from "../Button/Button";
import AutoSearch from "../AutoSearch/AutoSearch";
import "./patch.css";
import RoomsCountInput from "../RoomsCountInput/RoomsCountInput";
import {
  updateRooms,
  updateAdults,
  updateChildren,
} from "../../Redux/UserData/action";
import { useDispatch } from "react-redux";
import AdultCount from "../AdultCountInput/AdultCount";
import ChildrenCount from "../ChildrenCountInput/ChildrenCount";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

const Patch = ({ colorCode, btnColor, brandId }) => {
  const [roomsCount, setRoomsCount] = useState(1);
  const [adultsCount, setAdultsCount] = useState(1);
  const [selectedChildValue, setSelectedChildValue] = useState(0);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isPatchVisible, setIsPatchVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();

  const handleAdultChange = (e) => {
    const value = +e.target.value;
    setAdultsCount(value);
    dispatch(updateAdults(value));
  };

  const handleRoomCountChange = (e) => {
    const value = +e.target.value;
    setRoomsCount(value);
    dispatch(updateRooms(value));
  };

  const handleChildrenChange = (value) => {
    setSelectedChildValue(value);
    dispatch(updateChildren(value));
  };

  const toggleInputVisibility = () => {
    setIsInputVisible((prev) => !prev);
  };

  const togglePatchVisibility = () => {
    setIsPatchVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth <= 768;
      setIsMobile(isNowMobile);
      if (isNowMobile) {
        setIsPatchVisible(false); // Hide the patch on mobile view initially
      } else {
        setIsPatchVisible(true); // Show the patch on desktop view
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* Show the patch if it's visible */}
      {isPatchVisible && (
        <div
          className={`container-patch animated-slide-up ${isMobile ? "slide-up" : ""}`}
          style={{ backgroundColor: colorCode }}
        >
          <div className="wrapper">
            <div className="auto-search-name">
              <div className="hotel-icon">
                <ApartmentIcon />
              </div>
              <AutoSearch brandId={brandId} />
            </div>
            <div className="calendr-room-book-div">
              <div className="calendar-icon-div">
                <CalendarMonthIcon />
              </div>
              <Calender />
            </div>
            <div className="input-count-all">
              <p
                className="input-p-all-content"
                onClick={toggleInputVisibility}
                style={{ cursor: "pointer" }}
              >
                <div className="person-icon-toggle-div">
                  <PersonIcon />
                </div>
                <div>
                  {roomsCount} Room{roomsCount > 1 ? "s" : ""}, {adultsCount}{" "}
                  Adult{adultsCount > 1 ? "s" : ""}, {selectedChildValue} Children
                </div>
                <div className="expand-icon-toggle-div">
                  <ExpandMoreIcon />
                </div>
              </p>

              {isInputVisible && (
                <div className="input-count-second-div">
                  <RoomsCountInput
                    handleRoomCountChange={handleRoomCountChange}
                  />
                  <AdultCount
                    roomsCount={roomsCount}
                    handleInputChange={handleAdultChange}
                    selectedValue={adultsCount}
                  />
                  <ChildrenCount
                    roomsCount={roomsCount}
                    selectedValue={adultsCount}
                    handleChildrenChange={handleChildrenChange}
                  />
                  <button
                    className="toggle-input-button"
                    onClick={toggleInputVisibility}
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
            <div className="button-div-search">
              <Button btnColor={btnColor} />
            </div>
          </div>
          {/* Show the close icon only on mobile when the patch is visible */}
          {/* {isMobile && (
            <div className="close-icon" onClick={togglePatchVisibility}>
              <CloseIcon />
            </div>
          )} */}
        </div>

      )}

      {/* Show the Book Now button only when the patch is hidden on mobile */}
      {/* {!isPatchVisible && isMobile && (
        <div className="book-now-button-mobile" onClick={togglePatchVisibility}>
          Book Now
        </div>
      )} */}
    </>
  );
};

export default Patch;
